import { Typography } from '@mui/material';
import { DownloadLabel, StyledDottedButton, StyledDownloadIcon, TextLabel } from './controls';
import ContentLoader from '../../../../components/common/loader';

type OfferPromotionDownloadProps = {
  readonly label: string;
  readonly isFetching: boolean;
  readonly onClick: () => void;
};

export const OfferPromotionDownload = (props: OfferPromotionDownloadProps) => {
  const { label, isFetching, onClick } = props;

  return (
    <StyledDottedButton
      fullWidth
      disabled={isFetching}
      variant='outlined'
      onClick={onClick}
    >
      <TextLabel
        color='textPrimary'
        align='left'
      >
        {label}
      </TextLabel>
      <DownloadLabel>
        <StyledDownloadIcon fontSize='micro' />
        <Typography variant='body2'>скачать</Typography>
      </DownloadLabel>

      {isFetching && <ContentLoader />}
    </StyledDottedButton>
  );
};
